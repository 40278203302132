<template>
    <div>
        <el-form style="margin-top: 20px" :inline="true" class="demo-form-inline">
            <el-form-item>
                  <el-button @click="search" style="color: #ffffff; background-color: #ff7e00">查询</el-button>
                <el-button @click="openAddPlan" style="color: #ffffff; background-color: #ff7e00">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData">
            <el-table-column prop="admin_name" label="联系顾问"></el-table-column>
            <el-table-column label="计划内容">
                <template v-slot="{ row }">
                    <div class="ell" @click="planContentShow = true; planContent = row.content">{{ row.content }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="开始时间"  prop="start_time"> </el-table-column>
              <el-table-column label="创建时间"  prop="create_time"> </el-table-column>
            <el-table-column label="操作">
                <template v-slot="{ row }">
                    <div>
                        <el-button type="danger" size="small"
                            @click="deletePlan(row)">删除</el-button>
                             <!-- <el-button  type="success" size="small" @click="toPlanDetails(row)">详情</el-button> -->
                    </div>
                   
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy @currentPageChange="currentPageChange" :currentPage="currentPage" :total="total"></paging-fy>

        <!-- 回访计划 -->
        <el-dialog :visible.sync="dialogVisible" width="30%" title="添加联系记录" :destroy-on-close="true">
            <el-form label-width="80px">
                <el-form-item label="计划内容" required>
                    <el-input type="textarea" :rows="2" v-model="planForm.content" placeholder="请输入计划内容"></el-input>
                </el-form-item>
                <el-form-item label="执行人" ref="admin" required >
                    <div class="flex">  <admin-select ref="as" @change="changeConsultant" :placeholder="planForm.admin_name"></admin-select>
            <el-button type="text" @click="changeSelf">本人</el-button></div>
                </el-form-item>
                <el-form-item label="开始时间" required>
                    <el-date-picker v-model="planForm.start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker v-model="planForm.end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button v-if="mode == 'add'" style="background-color: #ff7e00; color: #ffffff" @click="addplan">确
                    定</el-button>
                <el-button v-else style="background-color: #ff7e00; color: #ffffff" @click="updateplan">修 改</el-button>
            </span>
        </el-dialog>

        <el-dialog title="计划内容" :visible.sync="planContentShow" width="30%">
            <p>
                {{ planContent }}
            </p>
        </el-dialog>
    </div>
</template>
  
<script>
import adminSelect from "@/components/select/adminSelect.vue"

export default {
    components: {
        adminSelect
    },
    data() {
        return {
            id:'',
            activeName: 'plan',
            tableData: [],
            currentPage: 1,
            total: 0,
            name: '',
            manager: JSON.parse(localStorage.getItem("managerInfo")),
            dialogVisible: false,
            planForm: {},
            recordForm: {},
            recordShow: false,
            curPlan: {},
            mode: 'add',
            user_name: "",
            planStatus: "",
            planStartTime: '',
            planEndTime: '',
            timeRange: [],
            planContentShow: false,
            planContent: '',
            recordContentShow: false,
            recordContent: '',
            coach_id:''
        };
    },
    created() {
        this.coach_id = this.$route.query.id
        this.getPlanList()
    },
    methods: {
        search(){
 this.getPlanList()
        },
        changeSelf(){
      this.$refs.as.selected_admin= this.manager.name
      this.planForm.admin_name = this.manager.name
      this.planForm.admin_id = this.manager.id
    },
        toPlanDetails(row) {
            this.$router.push({
                name: 'consultantPlanDetails',
                params: {
                    id: row.id
                }
            });
        },
        changeTime(v) {
            if (v.length >= 2) {
                this.planStartTime = v[0]
                this.planEndTime = v[1]
            } else {
                this.planStartTime = new Date().Format("yyyy-MM-dd") + ' 00:00:00'
                this.planEndTime = new Date().Format("yyyy-MM-dd") + ' 23:59:59'
            }
        },
        getPlanList() {
            let data = {
                pageSize: 10,
                currentPage: this.currentPage,
                coach_id: this.coach_id || null,
                type: 4,
            }
            let url = "/user/consultantPlan/queryManagerListPage";
            this.$axios
                .get(url, {
                    params: data,
                })
                .then((res) => {
                    this.tableData = res.data.data.rows
                    this.total = res.data.data.total
                });
        },
        currentPageChange(v) {
            this.currentPage = v
            this.getPlanList()
        },

        openRecord(plan) {
            this.curPlan = plan
            this.recordShow = true
        },
      
        openAddPlan() {
            this.dialogVisible = true
            this.mode = 'add'
            setTimeout(() => {
                console.log(this.$refs);
                this.$refs.user.clear()
                this.$refs.admin.clear()
            }, 200)

        },
        openUpdatePlan(plan) {
            this.mode = 'edit'
            this.dialogVisible = true
            this.planForm = plan
            this.$refs.user.clear()
            this.$refs.admin.clear()
        },
        cancelPlan(plan, status) {
            let t = status == 1 ? '取消' : '开启'
            this.$confirm(`·确认${t}回访计划?`, `${t}计划`).then(() => {
                this.$axios({
                    url: '/user/consultantPlan/update',
                    data: {
                        status,
                        id: plan.id
                    },
                    method: 'post'
                }).then(res => {
                    this.$message(`·${t}成功`)
                    this.getPlanList()
                })
            })
        },
        deletePlan(plan) {
            this.$confirm("确认删除回访计划？", "删除计划").then(() => {
                this.$axios({
                    url: '/user/consultantPlan/delete',
                    params: {
                        id: plan.id
                    },
                }).then(res => {
                    this.$message('任务已删除')
                    this.getPlanList()
                })
            })
        },
        changeUser(user) {
            this.planForm.user_id = user.user_id
            this.planForm.user_name = user.nick_name
        },
        changeMec(mec) {
            this.planForm.user_id = mec.id
            this.planForm.user_name = mec.mechanism_name
        },

        changeConsultant(admin) {
            this.planForm.admin_id = admin.id
            this.planForm.admin_name = admin.name
        },
        handlerOrderImg(v) {
            this.recordForm.order_img = v
        },
        addplan() {
            if (this.checkForm()) {
                this.planForm.coach_id = this.coach_id
                this.planForm.type = 4
                this.planForm.status = 2
                this.planForm.add_admin_id = this.manager.id
                this.planForm.add_admin_name = this.manager.name
                this.$axios({
                    url: '/user/consultantPlan/insert',
                    data: this.planForm,
                    method: 'post'
                }).then(res => {
                    this.$message("添加成功")
                    this.planForm = {}
                    this.dialogVisible = false
                    this.getPlanList()
                })
            }
        },
        updateplan() {
            if (this.checkForm()) {
                let data = {
                    id: this.planForm.id,
                    user_id: this.planForm.user_id,
                    user_name: this.planForm.user_name,
                    admin_id: this.planForm.admin_id,
                    admin_name: this.planForm.admin_name,
                    content: this.planForm.content,
                    start_time: this.planForm.start_time,
                    end_time: this.planForm.end_time
                }
                this.$axios({
                    url: '/user/consultantPlan/update',
                    data: data,
                    method: 'post'
                }).then(res => {
                    this.planForm = {}
                    this.dialogVisible = false
                    this.getPlanList()
                })
            }
        },
        checkForm() {
            if (!this.planForm.admin_name) {
                this.$message("请选择执行顾问")
                return false
            }
            if (!this.planForm.content) {
                this.$message("请输入内容")
                return false
            }
            if (!this.planForm.start_time) {
                this.$message("请选择开始时间")
                return false
            }
            return true
        },
        checkRecordForm() {
            if (!this.recordForm.contact_type) {
                this.$message("请选择联系方式")
                return false
            }
            if (!this.recordForm.contact_time) {
                this.$message("请选择联系时间")
                return false
            }
            if (!this.recordForm.contact_content) {
                this.$message("请输入内容")
                return false
            }
            if (this.curPlan.has_order == 1 && !this.recordForm.order_img) {
                this.$message("请上传订单图片，如合同，收款凭证")
                return false
            }
            if (this.curPlan.has_order == 1 && !this.recordForm.order_price) {
                this.$message("请输入订单金额")
                return false
            }
            return true
        },
    },
};
</script>
  
<style lang="less">
.ell {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: #0090ff;
    }
}
</style>
  