<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="">
        <el-button type="success" @click="getList(1)">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #pic="{ row }">
        <div>
          <img :src="row.pic" alt="" style="width: 40px; height: 40px" />
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button @click="del(row)">删除</el-button>
        </div>
      </template>
    </auto-table>

    <el-dialog :visible.sync="show" width="30%" title="新增">
      <el-form label-width="80px">
        <el-form-item label="名称" required>
           <el-input type="" placeholder="教育记录名称" v-model="form.name">
          </el-input>
        </el-form-item>
        <el-form-item label="专业名称" required>
          <el-input type="" placeholder="专业名称" v-model="form.major">
          </el-input>
        </el-form-item>
        <el-form-item label="排序" required>
          <el-input type="number" placeholder="越大越前" v-model="form.sort">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="addRecord"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "教育经历名称", value: "name" },
        { name: "专业", value: "major" },
        { name: "排序", value: "sort" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
      coach_id: "",
      show: false,
      form: {
        name: "",
        major: "",
        sort:''
      },
    };
  },
  mounted() {
    this.coach_id = this.$route.query.id;
    this.getList(1);
  },

  methods: {
    del(row) {
      this.$confirm("确认删除").then((res) => {
        this.$get("/user/coachEducationRecord/delete", { id: row.id }).then((res) => {
          this.getList(1);
        });
      });
    },
    add() {
      this.form = {
        name: "",
        pic: "",
      };
      this.show = true;
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/coachEducationRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    addRecord() {
      this.$axios({
        url: "/user/coachEducationRecord/insert",
        data: {
          coach_id: this.coach_id,
         ...this.form
        },
        method: "post",
      }).then((res) => {
        this.getList(1);
        this.show = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>