<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <div class="flex jc-end">
      <el-tooltip
        class="item"
        effect="dark"
        content="部分教练资质信息需要审核认证，请前往 教练管理-教练认证 审核"
        placement="left"
      >
        <el-button type="text">缺少信息?</el-button>
      </el-tooltip>
      <el-button type="text" @click="toExam" style="margin-left: 8px"
        >资质审核</el-button
      >
    </div>

    <el-row v-if="coachInfo.id">
      <el-col :span="12">
        <el-descriptions title="教练信息" :column="1">
          <el-descriptions-item
            :labelStyle="{ display: 'flex', alignItems: 'center' }"
            label="头像"
          >
            <el-image 
              style="width: 80px; border-radius: 4px; height: 80px"
              :src="coachInfo.head_portrait"
              :preview-src-list="coachInfo.head_portrait.split(',')"
            ></el-image>
          </el-descriptions-item>
          <el-descriptions-item label="姓名">
            <span>{{ coachInfo.name || "" }}</span>
            <span style="color:orange" v-if="coachInfo.coach_role == 'adviser'">顾问</span>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            coachInfo.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="教龄"
            >{{ coachInfo.teaching_age }}年</el-descriptions-item
          >
          <el-descriptions-item label="教学类别">{{
            coachInfo.teaching_categories
          }}</el-descriptions-item>
          <el-descriptions-item label="服务人群">
             <div class="flex">
              <p
                v-for="(type, index) in coachInfo.people_service.split(',')"
                :key="index"
                style="margin-right: 10px"
              >
                {{ service_people[type] }}
              </p>
            </div>
            </el-descriptions-item>
          <el-descriptions-item label="城市">{{
            coachInfo.city + coachInfo.district + coachInfo.address
          }}</el-descriptions-item>
          <el-descriptions-item label="服务方式">
            <div class="flex">
              <p
                v-for="(type, index) in coachInfo.teaching_type.split(',')"
                :key="index"
                style="margin-right: 10px"
              >
                {{ service[type] }}
              </p>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="注册时间">{{
            coachInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="教练级别"
            >{{ indent_imgs[coachInfo.identity_type].name }}

            <span v-if="coachInfo.stu_auth == 3">(学生认证)</span>
              <el-link  type="primary" v-if="coachInfo.coach_role != 'adviser'" :underline="false" @click="updateAdvise(false)">设为顾问</el-link>
          </el-descriptions-item>
          <el-descriptions-item label="是否展示">
            {{coachInfo.hide ? '不展示' : '展示'}}
          
            <el-link  type="primary" :underline="false" @click="updateHide(false)" v-if="coachInfo.hide">取消隐藏</el-link>
             <el-link  type="primary" :underline="false" @click="updateHide(true)" v-else>隐藏</el-link>
          </el-descriptions-item>
          <el-descriptions-item label="状态">
            <p v-if="coachInfo.status == 0">信息待提交</p>
            <p v-if="coachInfo.status == 1">待审核</p>
            <p v-if="coachInfo.status == 2">审核通过</p>
            <p v-if="coachInfo.status == 3">审核拒绝</p>
            <el-button
              type="success"
              @click="update(2)"
              v-if="coachInfo.status == 1"
              size="small"
              >通过</el-button
            >
            <el-button
              type="danger"
              @click="update(3)"
              v-if="coachInfo.status == 1"
              size="small"
              >拒绝</el-button
            >
          </el-descriptions-item>
        </el-descriptions>
      </el-col>

      <el-col :span="12">
        <el-descriptions
          title="资质信息"
          :column="1"
          v-if="coachInfo.certificate_pic"
        >
          <el-descriptions-item label="资格证书">
            <div class="flex">
              <div
                style="margin-right: 10px"
                v-for="(item, index) in coachInfo.certificate_pic"
                :key="index"
              >
                <el-image
                  class="pic"
                  :src="item"
                  :preview-src-list="coachInfo.certificate_pic"
                />
                <p
                  style="text-align: center"
                  v-if="coachInfo.class_certificate"
                >
                  {{
                    certificateMap[
                      coachInfo.class_certificate.split(",")[index].trim()
                    ] || ""
                  }}
                </p>
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="是否毕业">
            <div v-if="coachInfo.is_graduate === true">已毕业</div>
            <div v-if="coachInfo.is_graduate === false">未毕业</div>
          </el-descriptions-item>
          <el-descriptions-item label="毕业院校">
            {{ coachInfo.college }}
          </el-descriptions-item>
          <el-descriptions-item label="院校证书">
            <div class="flex">
              <div v-for="(item, index) in coachInfo.college_img" :key="index">
                <el-image
                  :key="index"
                  class="pic"
                  :src="item"
                  :preview-src-list="coachInfo.college_img"
                />
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="荣誉资质" v-if="coachInfo.honor_imgs">
            <div class="flex">
              <div
                style="margin-right: 10px"
                v-for="(item, index) in coachInfo.honor_imgs"
                :key="index"
              >
                <el-image
                  class="pic"
                  :src="item"
                  :preview-src-list="coachInfo.honor_imgs"
                />
                <p
                  style="text-align: center"
                  v-if="coachInfo.honor_qualification"
                >
                  {{ coachInfo.honor_qualification.split(",")[index] }}
                </p>
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <div v-if="auth_id" class="flex">
      <span class="title"> 教练认证审核：</span>
      <el-button type="success" @click="openAuth(1)">通过教练认证</el-button>
      <el-button style="margin-left: 10px" type="danger" @click="openAuth(2)"
        >拒绝教练认证</el-button
      >
    </div>
    <div v-else>
      <el-button type="warning" @click="Jump">修改教练信息</el-button>
      <el-button
        style="margin-left: 10px"
        type="danger"
        @click="openBind"
        v-if="isBind"
        >绑定机构管理</el-button
      >
    </div>

    <el-tabs v-model="activeName" @tab-click="tabHandleClick">
      <el-tab-pane label="执教经历" name="1">
        <div class="part-title">执教经历</div>
        <div class="flex flex-wrap" v-if="record.length > 0">
          <div class="record" v-for="item in record" :key="item.id">
            <el-descriptions :column="1" border>
              <el-descriptions-item
                label="执教机构"
                :labelStyle="{ 'min-width': '120px' }"
              >
                <span>{{ item.institution }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="执教时间"
                >{{ item.start_time.split(" ")[0] }}-{{
                  item.end_time.split(" ")[0]
                }}</el-descriptions-item
              >
              <el-descriptions-item label="执教职位">{{
                item.position
              }}</el-descriptions-item>
              <el-descriptions-item label="执教内容">{{
                item.content
              }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div v-else class="noInfo">暂无信息</div>
      </el-tab-pane>

      <el-tab-pane label="保证金管理" name="2">
        <div class="flex jc-b">
          <div class="cash-box">
            <p>保证金：{{ coachInfo.bond }}</p>
          </div>
          <el-button type="warning" v-permission="'subCoachBond'" @click="openBond">扣除保证金</el-button>
        </div>
        <div>
          <el-table
            :data="bondList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="id" prop="id"></el-table-column>
            <el-table-column label="课程名称" prop="title"></el-table-column>
            <el-table-column label="保证金" prop="amount"></el-table-column>
            <el-table-column label="备注" prop="remark"></el-table-column>
            <el-table-column
              label="创建时间"
              prop="create_time"
            ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy
            @currentPageChange="changeBondCurrentPage"
            :currentPage="bondPage"
            :total="bondTotal"
          ></paging-fy>
        </div>
      </el-tab-pane>

      <el-tab-pane label="课程管理" name="3">
        <coachneed-list :cid="coach_id" ref="cneed"></coachneed-list>
      </el-tab-pane>
      <el-tab-pane label="核销列表" name="4">
        <slettement-list :cid="coach_id" ref="slist"></slettement-list>
      </el-tab-pane>
       <el-tab-pane label="收益列表" name="5">
        <coachIncome ref="cincome"></coachIncome>
      </el-tab-pane>
      
      <el-tab-pane label="邀请列表" name="6">
        <div style="margin: 10px 0">
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="invitation_choice"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            style="margin-left: 50px"
            type="primary"
            @click="invitation_search"
            >搜索</el-button
          >
           <el-button
            style="margin-left: 50px"
            type="success"
            v-permission="'addCoachReward'" 
            @click="cashVisible = true"
            >添加奖励</el-button
          >
        </div>
        <div>
          <el-table
            :data="invitationList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="id" prop="coach_id"></el-table-column>
            <el-table-column label="姓名" prop="coach_name"></el-table-column>
            <el-table-column label="电话" prop="coach_phone"></el-table-column>
             <el-table-column label="类型" prop="coach_phone">
               <template slot-scope="{ row }">
                <p v-if="row.type == 2">邀请注册</p>
                <p v-if="row.type == 3">开会员</p>
                 <p v-if="row.type == 4">创建公会</p>
              </template>
             </el-table-column>
            <el-table-column
              label="邀请时间"
              prop="create_time"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <p v-if="row.status == 0">邀请中</p>
                <p v-if="row.status == 1">邀请成功</p>
                 <p v-if="row.status == 4">邀请成功</p>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy
            @currentPageChange="invitationCurrentPage"
            :currentPage="invitationPage"
            :total="invitationTotal"
          ></paging-fy>
        </div>
      </el-tab-pane>
      <el-tab-pane label="联系记录" name="7">
        <coachPlan></coachPlan>
      </el-tab-pane>
      <el-tab-pane label="审核记录" name="exam">
        <exam-list ref="exam"></exam-list>
      </el-tab-pane>
      <el-tab-pane label="教练技能" name="skill">
        <coach-skill ref="skill"></coach-skill>
      </el-tab-pane>
      <el-tab-pane label="教育背景" name="edu">
        <coach-education ref="edu"></coach-education>
      </el-tab-pane>
    </el-tabs>

    <!-- 扣除保证金 -->
    <el-dialog title="扣除保证金" :visible.sync="bondVisible" width="30%">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="扣除金额">
            <el-input v-model="bondAmount"></el-input>
          </el-form-item>
          <el-form-item label="扣除理由">
            <el-input type="textarea" v-model="refuse"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bondVisible = false">取 消</el-button>
        <el-button type="primary" @click="subBond">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加奖励 -->
    <el-dialog title="添加奖励" :visible.sync="cashVisible" width="30%">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="奖励金额">
            <el-input v-model="cashAmount"></el-input>
          </el-form-item>
          <el-form-item label="奖励类型">
            <!-- <el-radio v-model="cash_type" label="1">注册</el-radio> -->
            <el-radio v-model="cash_type" label="2">邀请</el-radio>
            <el-radio v-model="cash_type" label="3">奖励</el-radio>
          </el-form-item>
          <el-form-item label="奖励标题">
            <el-input type="textarea" v-model="remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCash">确 定</el-button>
      </span>
    </el-dialog>

    <coachBind ref="bind" @success="getCoachBind"></coachBind>

    <!-- 认证审核意见 -->
    <el-dialog title="认证审核意见" :visible.sync="authShow" width="30%">
      <div>
        <el-form label-width="80px">
          <el-form-item label="审核结果">
            <div v-if="auth_status === 1">通过教练认证</div>
            <div v-if="auth_status === 2">拒绝教练认证</div>
          </el-form-item>
          <el-form-item label="说明" v-if="auth_status === 1">
            <el-input type="textarea"  placeholder="如对教练的评价，说明等(选填)"   :autosize="{ minRows: 3, maxRows: 6}" v-model="auth_remark"></el-input>
          </el-form-item>
          <el-form-item label="拒绝理由" required v-if="auth_status === 2">
            <el-input type="textarea" placeholder="拒绝理由"  :autosize="{ minRows: 3, maxRows: 6}"  v-model="auth_refuse"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="authShow = false">取 消</el-button>
        <el-button type="primary" @click="handleAuth" v-if="auth_status === 1"
          >通 过</el-button
        >
        <el-button type="primary" @click="handleAuth" v-if="auth_status === 2"
          >拒 绝</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachneedList from "./components/coachneedList.vue";
import slettementList from "./components/slettementList.vue";
import coachBind from "./components/coachBind.vue";
import coachPlan from "./components/coachPlan.vue";
import examList from "./components/examList.vue";
import coachIncome from './components/coachIncome.vue';
import coachSkill from './components/coachSkill.vue';
import coachEducation from './components/coachEducation.vue';
export default {
  components: {
    coachneedList,
    slettementList,
    coachBind,
    coachPlan,
    examList,
    coachIncome,
    coachSkill,
    coachEducation
  },
  data() {
    return {
      coach_id: "",
      coachInfo: {},
      nav: { firstNav: "教练管理", secondNav: "教练详情" },
      record: [],
      bondList: [],
      bondPage: 1,
      bondTotal: 0,
      bondAmount: 0,
      cashList: [],
      cashPage: 1,
      cashTotal: 0,
      cashAmount: 0,
      cash_type: "2",
      remark: "",
      refuse: "",
      service_people: {
        1: "成人",
        2: "学龄前",
        3: "学龄",
        4: "亲子",
      },
      service: {
        1: "海马场地",
        2: "上门教学",
        3: "教练场地",
        4: "陪练",
      },
      indent_imgs: {
        1: {
          name: "普通教练",
          pic: "../../assets/imgs/coach_1.png",
          desc: "",
        },
        2: {
          name: "认证教练",
          pic: "../../assets/imgs/coach_2.png",
          desc: "",
        },
        3: {
          name: "金牌教练",
          pic: "../../assets/imgs/coach_3.png",
          desc: "",
        },
      },
      bondVisible: false,
      cashVisible: false,
      activeName: "1",
      bindList: [],
      isBind: false,
      certificateMap: {
        1: "初级证书",
        2: "中级证书",
        3: "高级证书",
        4: "特级证书",
      },
      certificate: [
        {
          name: "",
          id: 0,
        },
        {
          name: "初级证书",
          id: 1,
        },
        {
          name: "中级证书",
          id: 2,
        },
        {
          name: "高级证书",
          id: 3,
        },
        {
          name: "特级证书",
          id: 4,
        },
      ],
      invitationPage: 1,
      invitationTotal: 1,
      invitation_status: 2,
      invitationList: [],
      options: [
        {
          value: 2,
          label: "全部",
        },
        {
          value: 0,
          label: "未认证",
        },
        {
          value: 1,
          label: "已认证",
        },
      ],
      value: "全部",
      auth_id: "",
      authShow: false,
      auth_remark: "",
      auth_refuse: "",
      auth_status: "",
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },
  created() {
    this.coach_id = this.$route.query.id;
    this.auth_id = this.$route.query.auth_id;
    if (this.coach_id) {
      this.getCoach();
      this.getBondList();
      this.invitation();
      this.getCoachBind();
    }
  },
  methods: {
    handleAuth() {
      let data = {
        id: this.auth_id,
        status: this.auth_status,
        admin_id: this.adminInfo.id,
        admin_name: this.adminInfo.name,
      };

      if (this.auth_status === 1) {
        data.remark = this.auth_remark;
      } else {
        if(!this.auth_refuse){
          this.$message("填写拒绝理由")
          return 
        }
        data.refuse = this.auth_refuse;
      }

      this.$post("user/authApply/update",data).then((res) => {
        this.authShow = false
        this.$router.back();
      });
    },
    openAuth(status) {
      this.auth_status = status;
      this.authShow = true;
    },
    openBind() {
      this.$refs.bind.display(true, this.coach_id, this.bindList);
    },
    // 教练是否绑定机构
    getCoachBind() {
      this.$axios({
        url: "user/coachMechanismConnected/query",
        params: {
          coach_id: this.$route.query.id,
          mechanism_id: -1,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.bindList = res.data.data;
          this.isBind = true;
        }
      });
    },
    Jump() {
      this.$router.push({
        name: "Coachmodification",
        query: {
          id: this.$route.query.id,
        },
      });
    },

    invitationCurrentPage(v) {
      this.invitationPage = v;
      this.invitation();
    },
    invitation_choice(v) {
      this.invitation_status = v;
    },
    invitation_search() {
      this.invitation();
      this.invitationPage = 1;
    },
    // 邀请列表
    invitation() {
      let data = {
        invitation_id: this.coach_id,
        pageSize: 10,
        currentPage: this.invitationPage,
      };
      if (this.invitation_status != 2) {
        data.status = this.invitation_status;
      }
      this.$get("user/coachInvitationRecord/queryManagerListPage", data).then(
        (res) => {
          this.invitationList = res.data.data.rows;
          this.invitationTotal = res.data.data.total;
        }
      );
    },
    toExam() {
      this.$router.push({
        name: "coachCardDetails",
        query: {
          id: this.coachInfo.attestation_record_id,
          coach_id: this.coachInfo.id,
        },
      });
    },
    // 增加奖励金额
    addCash() {
      if (this.cashAmount <= 0) {
        this.$message({ message: "请填写金额" });
        return;
      }
      let url = "/user/coach/updateCash";
      let data = {
        id: this.coach_id,
        type: this.cash_type,
        cash: this.cashAmount,
        remark: this.remark,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "添加成功" });
          this.getCoach();
          this.getCashList();
          this.cashVisible = false;
        }
      });
    },
    // 扣除保证金
    subBond() {
      if (this.bondAmount <= 0) {
        this.$message({ message: "请填写金额" });
        return;
      }
      let url = "/user/coach/updateBond";
      let data = {
        id: this.coach_id,
        type: 2,
        bond: "-" + this.bondAmount,
        remark: this.refuse,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "扣除成功" });
          this.getCoach();
          this.getBondList();
          this.bondVisible = false;
        }
      });
    },
    openBond() {
      this.bondVisible = true;
    },
    changeBondCurrentPage(v) {
      this.bondPage = v;
      this.getBondList();
    },
    changeCashCurrentPage(v) {
      this.bondPage = v;
      this.getCashList();
    },
    //奖励记录
    getCashList() {
      let url = "/user/coachCashRecord/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.cashPage,
        coach_id: this.coach_id,
        types: "1,2,3",
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.cashList = res.data.data.rows;
          this.cashTotal = res.data.data.total;
        });
    },
    //保证金记录
    getBondList() {
      let url = "/user/coachBondRecord/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.bondPage,
        coach_id: this.coach_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.bondList = res.data.data.rows;
          this.bondTotal = res.data.data.total;
        });
    },
    tabHandleClick(v) {
      if (v.name == 3) {
        this.$refs.cneed.init();
      }
      if (v.name == 4) {
        this.$refs.slist.init();
      }
      if (v.name == 5) {
         this.$refs.cincome.init();
      }
      if (v.name == "exam") {
        this.$refs.exam.init();
      }
    },
    //更新状态
    update(status) {
      let url = "/user/coach/update";
      let data = {
        id: this.coachInfo.id,
        status,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.getCoach();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    updateAdvise(){
        let url = "/user/coach/update";
      let data = {
        id: this.coachInfo.id,
        coach_role:'adviser'
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$message({ message: "成功" });
        this.coachInfo.coach_role = 'adviser'
      });
    },

    updateHide(v){
        let url = "/user/coach/update";
      let data = {
        id: this.coachInfo.id,
        hide:v,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$message({ message: "成功" });
        this.coachInfo.hide = v
      });
    },
    //教练信息
    getCoach() {
      let url = "/user/coach/findById";
      let params = {
        id: this.coach_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          let info = res.data.data;
          info.honor_qualification_imgs &&
            (info.honor_imgs = info.honor_qualification_imgs.split(","));
          info.certificate_pic = info.certificate_pic.split(",");
          info.college_img && (info.college_img = info.college_img.split(","));
          this.coachInfo = info;
          this.getCoachRecode();
        });
    },
    //执教经历
    getCoachRecode() {
      let url = "/user/coachTeachingRecord/query";
      let params = {
        coach_id: this.coach_id,
        attestation_record_id: this.coachInfo.attestation_record_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.record = res.data.data;
        });
    },
  },
};
</script>

<style>
.cell {
  text-align: center;
}

.pic {
  width: 100px;
  height: 100px;
}

.record {
  box-sizing: border-box;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.part-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.cash-box {
  font-size: 16px;
  line-height: 50px;
  font-weight: bold;
}

.i-pic {
  width: 20px;
  height: 20px;
}
.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
}
</style>
